import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Container from "react-bootstrap/Container"

import contentStyle from "../styles/modules/content.module.scss"
import SEO from "../components/seo"

var classNames = require("classnames")

export default ({ data }) => {
  const post = data.markdownRemark

  // const body = document.getElementsByTagName("body")[0];
  // body.classList.remove('home');

  const backgrounds = ["", "dark-background"]
  const backgroundClass =
    backgrounds[Math.floor(Math.random() * backgrounds.length)]

  let wrapperClasses = classNames(contentStyle.contentWrapper)
  return (
    <div className={wrapperClasses} style={{ marginBottom: "10px" }}>
      <SEO title={post.frontmatter.title} />
      <Layout>
        <Container className="full-height container-sm">
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </Container>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
